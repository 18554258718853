<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="区块"
    add-btn="新增区块"
    add-route="NicheAreaAdd"
    hide-show-detail
    hide-edit
    hide-delete
    :columns="columns"
    :action="action"
    :request-config="{ noTempleFilter: true }"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="所属区域">
        <a-select
          style="width: 180px"
          placeholder="选择区域查询"
          allow-clear
          @change="updateList"
          :options="areaList"
          v-model="form.district_id"
        >
        </a-select>
      </a-form-model-item>
    </template>
    <template v-slot:action="{ item, index }">
      <a class="txt-btn" @click.stop="editRow(item, index)" v-action:update>编辑</a>
      <a class="txt-btn danger" @click.stop="delRow(item, index)" v-action:delete>删除</a>
    </template>
  </data-list>
</template>

<script>
import { dealNicheList } from "./light";
export default {
  name: "NicheArea",
  data() {
    return {
      url: "/admin/mini-worship-area",
      columns: [
        { title: "区块名称", dataIndex: "name" },
        {
          title: "所属区域",
          dataIndex: "district",
          customRender: (text) => {
            return <span>{text?.name}</span>;
          },
        },
        {
          title: "龛位数量",
          dataIndex: "buddhist_niche_id",
          customRender: (text) => {
            return text?.split(",").length || 0;
          },
        },
        {
          title: "是否开放",
          customRender: (record) => {
            return (
              <a-switch checked={record.status == 1} onChange={($event) => this.onChange($event, record)}></a-switch>
            );
          },
        },
      ],
      areaList: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getArea();
  },
  methods: {
    getArea() {
      this.$axios({
        url: "/admin/mini-worship-district",
        method: "GET",
        params: {
          expand: "buddhistNiche",
        },
        noTempleFilter: true,
      }).then((res) => {
        let areaList = res.data;
        areaList.forEach((area) => {
          area.key = area.id;
          area.title = area.name;
          area.list = dealNicheList(area.buddhistNiche);
        });
        this.areaList = areaList;
      });
    },
    onChange(checked, record) {
      let text = checked ? "开放" : "关闭";
      this.$confirm({
        title: "提示",
        content: `确定${text}此区块吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${record.id}`,
            method: "PATCH",
            data: {
              status: checked ? 1 : 2,
            },
          }).then((res) => {
            record.status = res.status;
            this.$message.success(text + "成功");
          });
        },
      });
    },
    editRow(item) {
      this.$router.push({ name: "NicheAreaDetail", params: { id: item.id }, query: { edit: 1 } });
    },
    delRow(item, index) {
      this.$refs.dataList.delRow(item, index);
    },
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped>
.tip-msg {
  color: grey;
}
</style>
